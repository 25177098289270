import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { maxLengthCreator, required } from '../validate';
import { Input } from '../formControls';
import { ADD_FORM } from '../../../utils/generalConstants';
import { initialValuesSelector } from './selectors';
import './styles.scss';
import Button from '../../UI/button';

const maxLength24 = maxLengthCreator(24);

const AddModalForm = memo(({
  handleSubmit, submitting, pristine, invalid, handleCloseModal, existedValue,
}) => (
  <form onSubmit={handleSubmit} className="add-device-modal-form">
    <Field
      className="add-device-modal-form__input"
      name="id"
      type="text"
      placeholder="Id of device"
      label="ID:"
      validate={[required, maxLength24, existedValue]}
      component={Input}
    />
    <Field
      className="add-device-modal-form__input"
      name="name"
      type="text"
      placeholder="Name of device"
      label="Name*:"
      validate={[required, existedValue]}
      component={Input}
    />
    <div className="add-device-modal-form__buttons">
      <Button text="SUBMIT" type="submit" disabled={invalid || submitting || pristine} />
      <Button text="CANCEL" onClick={handleCloseModal} disabled={submitting} />
    </div>

  </form>
));

AddModalForm.propTypes = {
  handleCloseModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  existedValue: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
};

const form = reduxForm({
  form: ADD_FORM,
})(AddModalForm);

const mapStateToProps = createStructuredSelector({
  initialValues: initialValuesSelector,
});

export default connect(mapStateToProps)(form);

import { createSelector } from 'reselect';

const getCurrentDevice = (state) => state?.currentDevice;
const idSelector = createSelector([getCurrentDevice], (device) => device?.id);
const nameSelector = createSelector([getCurrentDevice], (device) => device?.name);

export const initialValuesSelector = createSelector(
  [idSelector, nameSelector],
  (id, name) => ({
    id: id || '',
    name: name || '',
  }),
);

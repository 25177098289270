import { STATUS_ONLINE } from './constants';

const initialState = { isConnect: true };

export const connectionReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case STATUS_ONLINE:
      return {
        ...state,
        isConnect: payload,
      };
    default:
      return state;
  }
};

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const Button = memo(({
  onClick, text, type, className, disabled,
}) => (
  <button
    type={type}
    className={className || (type === 'submit' ? 'save-btn' : 'custom-btn')}
    onClick={onClick}
    disabled={disabled}
  >
    {text}
  </button>
));

Button.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.string,
};

Button.defaultProps = {
  type: 'button',
  onClick: () => { },
  className: '',
  disabled: false,
};
export default Button;

import { SET_NOTIFICATION } from './constants';

export const notificationReducer = (state = '', { type, payload }) => {
  switch (type) {
    case SET_NOTIFICATION:
      return payload.text;
    default:
      return state;
  }
};

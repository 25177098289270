import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Routes, Route } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import TopBar from './components/TopBar';
import NoInternetConnection from './components/NoInternetConnection';
import routes from './routes';
import 'bootstrap/dist/css/bootstrap.min.css';
import { setOnlineStatus } from './components/NoInternetConnection/actions';
import Notification from './components/Notification';
import { isConnectSelector } from './selectors';

const App = ({ setOnlineStatus: setOnlineStatusFromProps, isConnect }) => {
  const setOnline = () => setOnlineStatusFromProps(true);
  const setOffline = () => setOnlineStatusFromProps(false);

  useEffect(() => {
    window.addEventListener('offline', setOffline);
    window.addEventListener('online', setOnline);

    return () => {
      window.removeEventListener('offline', setOffline);
      window.removeEventListener('online', setOnline);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="App">
      {isConnect ? (
        <>
          <TopBar />
          <Routes>
            {routes.map((route) => (
              <Route {...route} />
            ))}
          </Routes>
        </>
      ) : (
        <NoInternetConnection />
      )}
      <Notification />
    </div>
  );
};

App.propTypes = {
  setOnlineStatus: PropTypes.func.isRequired,
  isConnect: PropTypes.bool.isRequired,
};

const mapStateToProps = createStructuredSelector({
  isConnect: isConnectSelector,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({ setOnlineStatus }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);

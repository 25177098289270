import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import { connectionReducer } from '../components/NoInternetConnection/reducer';
import { notificationReducer } from '../components/Notification/reducer';
import { currentDeviceReducer } from '../components/DevicesTable/modals/SettingsModal/reducer';
import {
  modalReducer,
  storageDevicesReducer,
} from '../components/DevicesTable/reducer';

export const rootReducer = combineReducers({
  form: formReducer,
  notificationText: notificationReducer,
  internetConnection: connectionReducer,
  modal: modalReducer,
  currentDevice: currentDeviceReducer,
  storageDevices: storageDevicesReducer,
});

const store = configureStore({ reducer: rootReducer });

export default store;

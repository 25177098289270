import { createSelector } from 'reselect';

const getIsOpen = (state) => state.modal?.isOpen;
const getTypeOfModal = (state) => state.modal?.typeOfModal;
const getCurrentDevice = (state) => state.currentDevice;
const getDevices = (state) => state.storageDevices;

export const isOpenSelector = createSelector([getIsOpen], (isOpen) => isOpen);
export const typeOfModalSelector = createSelector([getTypeOfModal], (typeOfModal) => typeOfModal);
export const currentDeviceSelector = createSelector([getCurrentDevice], (currentDevice) => currentDevice);
export const devicesSelector = createSelector([getDevices], (devices) => devices);

import axios from 'axios';
import { KEY, LOCAL_STORAGE } from './generalConstants';

export const filterDevices = (elementParam, dataList, param = 'name') => dataList.filter((item) => item[param] !== elementParam);

export const connectToDeviceAndSubscribeToUpdates = async ({
  formValues: { password, name, measuring }, addNotificationFromProps,
}) => {
  // ↓ Credentials ↓
  const SSID_CHARACTERISTIC_UUID = 'ec5397a2-0afe-4bd9-9403-0cd230976fa8';
  const PASSWORD_CHARACTERISTIC_UUID = '1fc79774-fb73-453e-af23-61439d87a389';
  const INTERVAL_CHARACTERISTIC_UUID = '4b411f8a-fcb8-11ea-adc1-0242ac120002';
  const SWITCH_CHARACTERISTIC_UUID = '2dc43574-fb73-453e-af23-61439d87a389';
  const serviceUUID = '19b10000-e8f2-537e-4f6c-d104768a1214';

  try {
    // ↓ Search for Bluetooth devices that advertise a battery service ↓
    const device = await navigator.bluetooth
      .requestDevice({ acceptAllDevices: true, optionalServices: [serviceUUID] });

    // ↓ Add an event listener to detect when a device disconnects ↓
    device.addEventListener('gattserverdisconnected', (event) => {
      (addNotificationFromProps(`The device ${event.target.name} is disconnected`));
    });

    // ↓ Connect to the remote GATT Server running on the Bluetooth device ↓
    const server = await device.gatt.connect();
    if (server.connected === true) {
      (addNotificationFromProps(`Connected to ${device.name}`));
    }

    // ↓ Get the serviceUUID from the Bluetooth device ↓
    const service = await server.getPrimaryService(serviceUUID);

    // ↓ Get characteristics from the Bluetooth device ↓
    const SSID = await service.getCharacteristic(SSID_CHARACTERISTIC_UUID);
    const PASSWORD = await service.getCharacteristic(PASSWORD_CHARACTERISTIC_UUID);
    const INTERVAL = await service.getCharacteristic(INTERVAL_CHARACTERISTIC_UUID);
    const SWITCH = await service.getCharacteristic(SWITCH_CHARACTERISTIC_UUID);

    // ↓ Send values to device ↓
    (addNotificationFromProps('Send values...'));
    const encoder = new TextEncoder();

    const uint8Name = encoder.encode(name); // example: 'EE@05kilogram'
    await SSID.writeValueWithResponse(uint8Name);

    const uint8Pass = encoder.encode(password); // example: 'EE'
    await PASSWORD.writeValueWithResponse(uint8Pass);

    const uint8Interval = encoder.encode(measuring); // example: '2'
    await INTERVAL.writeValueWithResponse(uint8Interval);

    const uint8Switch = encoder.encode(1);
    await SWITCH.writeValueWithResponse(uint8Switch);

    // ↓ Disconnect from the remote GATT Server ↓
    await device.gatt.disconnect();
  } catch (error) {
    console.error(`There was an error: ${error}`);
  }
};

export const fetchDeviceData = async (uri) => {
  try {
    if (typeof uri !== 'string' && !uri) {
      return {};
    }
    const resultData = await axios.get(uri);
    if (resultData?.status === 200) {
      const {
        t: temperature,
        H: humidity,
        P: pressure,
        CO2: co2,
        TVOC: tvoc,
      } = resultData?.data?.value?.payload?.data;
      return {
        temperature, humidity, pressure, co2, tvoc,
      };
    }
  } catch (error) {
    console.error('some error', error);
  }
};

export const fetchTelemetry = (setTelemetry) => {
  axios
    .get('https://dashboard-api.ubreez.com/data')
    .then((res) => {
      if (res?.status === 200) {
        const {
          t: temperature,
          H: humidity,
          P: pressure,
          CO2: co2,
          TVOC: tvoc,
        } = res?.data?.data;
        setTelemetry({
          temperature, humidity, pressure, co2, tvoc,
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const initialDevice = {
  id: null,
  name: null,
  temperature: { min: 20, max: 25 },
  humidity: { min: 40, max: 60 },
  pressure: { min: 730, max: 760 },
  co2: { min: 500, max: 1000 },
  tvoc: { min: 200, max: 500 },
};
export const defaultLimits = {
  temperature: { min: -50, max: 50 },
  humidity: { min: 0, max: 100 },
  pressure: { min: 700, max: 800 },
  co2: { min: 410, max: 2000 },
  tvoc: { min: 0, max: 500 },
};
export const initialState = {
  temperature: null,
  humidity: null,
  pressure: null,
  co2: null,
  tvoc: null,
};

export const currentDevicesFromStorage = JSON.parse(localStorage.getItem(KEY)) || [];
export const copyOfStorage = JSON.parse(JSON.stringify(currentDevicesFromStorage));
export const typeOfSending = LOCAL_STORAGE;

export const getNewLocalStorage = (currentDevice, state, newValues) => {
  const editedDeviceIndex = state.findIndex((device) => device.id === currentDevice.id);
  const editedDevice = state[editedDeviceIndex];
  const newEditDevice = {
    ...editedDevice,
    ...newValues,
  };
  return [
    ...state.slice(0, editedDeviceIndex),
    newValues ? newEditDevice : currentDevice,
    ...state.slice(editedDeviceIndex + 1),
  ];
};

export const setNewDevices = (arrayOfDevices = [], type = '') => {
  const result = copyOfStorage;
  switch (type) {
    case LOCAL_STORAGE:
      localStorage.setItem(KEY, JSON.stringify(arrayOfDevices));
      break;
    default:
      break;
  }
  return arrayOfDevices.length ? arrayOfDevices : result;
};

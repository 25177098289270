import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as HumidityGauge } from '../../../assets/images/HumidityGauge.svg';
import { ReactComponent as HumidityIcon } from '../../../assets/images/HumidityIcon.svg';
import { ReactComponent as InfoTipIcon } from '../../../assets/images/InfoTipIcon.svg';

import './styles.scss';

const Humidity = (props) => {
  const { value, deviceHumidity: { min: MIN_VALUE, max: MAX_VALUE } } = props;

  const showWarning = !!value && (value < MIN_VALUE || value > MAX_VALUE);

  const humidityLevelRef = useRef(null);

  if (humidityLevelRef?.current) {
    const humidityLevelEl = humidityLevelRef?.current;
    const humidityLevel = humidityLevelEl?.querySelector('#humidity-level');
    const X = value * (humidityLevelEl.clientHeight / 100);
    const d = `M0.00, ${220.00 - X} C110.00, ${245.00 - X} 110.00, ${195.00 - X} 220.00, ${220.00 - X} L220.00, 220.00 L0.00, 220.00 Z`;
    humidityLevel.setAttribute('d', d);
  }

  return (
    <div className={`humidity-container ${showWarning ? 'warning' : ''}`}>
      <div className="humidity-label-container">
        <HumidityIcon />
        <div className="humidity-label">HUMIDITY</div>
        <InfoTipIcon className="info-tip" />
      </div>
      <div className="humidity-gauge-container">
        <HumidityGauge className="humidity-gauge-scale" ref={humidityLevelRef} />
        <div className="humidity-value-container">
          <div className="humidity-value">{value || 0}</div>
          <div className="humidity-unit">%</div>
        </div>
      </div>
      <div className="set-limits-container">
        <span className="set-limits-label">Set limits:</span>
        <span className="set-limits-value">{`${MIN_VALUE}%`}</span>
        <span className="set-limits-label"> - </span>
        <span className="set-limits-value">{`${MAX_VALUE}%`}</span>
      </div>
    </div>
  );
};

Humidity.propTypes = {
  value: PropTypes.number.isRequired,
  deviceHumidity: PropTypes.instanceOf(Object),
};

Humidity.defaultProps = {
  deviceHumidity: { min: 40, max: 60 },
};

export default Humidity;

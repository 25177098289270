import React from 'react';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';
import QrReader from 'react-qr-reader';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { resetNewCurrentDevice } from '../SettingsModal/actions';
import { setClose, setEditData, setNewDevice } from '../../actions';
import AddModalForm from '../../../Forms/AddModalForm';
import { validation } from '../../../Forms/validate';
import {
  currentDeviceSelector, devicesSelector, isOpenSelector, typeOfModalSelector,
} from './selectors';
import {
  ADD_FORM, ADD_MODAL, EDIT_MODAL, QR_MODAL,
} from '../../../../utils/generalConstants';
import './styles.scss';

const AddDeviceModal = ({
  isOpen, typeOfModal, currentDevice, devices,
  setClose: setCloseFromProps,
  setEditData: setEditDataFromProps,
  setNewDevice: setNewDeviceFromProps,
  resetNewCurrentDevice: resetNewCurrentDeviceFromProps,
  change: changeFromProps,
}) => {
  const existedValue = validation(devices);

  const handleScan = (id) => id && changeFromProps(ADD_FORM, 'id', id);
  const handleError = (err) => console.error(err);

  const handleCloseModal = () => {
    setCloseFromProps();
    resetNewCurrentDeviceFromProps();
  };

  const handleSubmit = ({ id, name }) => {
    switch (typeOfModal) {
      case QR_MODAL:
      case ADD_MODAL: {
        setNewDeviceFromProps({ id, name });
        break;
      }
      case EDIT_MODAL: {
        setEditDataFromProps({ id, name }, currentDevice);
        break;
      }
      default:
        break;
    }
    handleCloseModal();
  };

  return (
    <Modal
      show={isOpen}
      onHide={handleCloseModal}
    >
      <div className="add-device-modal">
        {typeOfModal === QR_MODAL && (
          <div className="add-device-modal__QR">
            <h1 className="add-device-modal__QR-title">Add new device</h1>
            <QrReader
              className="add-device-modal__QR-reader"
              delay={400}
              onError={handleError}
              onScan={handleScan}
            />
          </div>
        )}
        {[ADD_MODAL, EDIT_MODAL].includes(typeOfModal) && (
          <div className="add-device-modal__description">
            <h1 className="add-device-modal__description-title">Write your values</h1>
            <div className="add-device-modal__description-text">
              * Enter a name for this Ubox (no longer than 15 characters). We
              suggest naming by location, for example: Kitchen
            </div>
          </div>
        )}
        <AddModalForm
          onSubmit={handleSubmit}
          handleCloseModal={handleCloseModal}
          existedValue={existedValue}
        />
      </div>
    </Modal>
  );
};

AddDeviceModal.propTypes = {
  currentDevice: PropTypes.instanceOf(Object).isRequired,
  devices: PropTypes.instanceOf(Object).isRequired,
  resetNewCurrentDevice: PropTypes.func.isRequired,
  typeOfModal: PropTypes.string.isRequired,
  setNewDevice: PropTypes.func.isRequired,
  setEditData: PropTypes.func.isRequired,
  setClose: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = createStructuredSelector({
  isOpen: isOpenSelector,
  typeOfModal: typeOfModalSelector,
  currentDevice: currentDeviceSelector,
  devices: devicesSelector,
});
const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({
    setClose,
    setEditData,
    setNewDevice,
    resetNewCurrentDevice,
    change,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddDeviceModal);

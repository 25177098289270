import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as CO2Scale } from '../../../assets/images/CO2Scale.svg';
import { ReactComponent as CO2Icon } from '../../../assets/images/CO2Icon.svg';
import { ReactComponent as InfoTipIcon } from '../../../assets/images/InfoTipIcon.svg';
import { ReactComponent as Needle } from '../../../assets/images/Needle.svg';

import './styles.scss';

const CO2 = (props) => {
  const { value, deviceCo2: { min: MIN_VALUE, max: MAX_VALUE } } = props;
  const START = 0;
  const END = 5000;

  const showWarning = !!value && (value < MIN_VALUE || value > MAX_VALUE);

  const gaugeRef = useRef(null);
  const needleRef = useRef(null);

  if (gaugeRef?.current && needleRef?.current) {
    const gaugeEl = gaugeRef?.current;
    const needleEl = needleRef.current;

    if (value < START + needleEl.clientWidth / 2) {
      needleEl.style = `left: -${needleEl.clientWidth / 2}px`;
    } else if (value > END) {
      needleEl.style = `left: calc(${gaugeEl.clientWidth}px - ${needleEl.clientWidth / 2}px)`;
    } else {
      needleEl.style = `left: calc(${value / (END / gaugeEl.clientWidth)}px - ${needleEl.clientWidth / 2}px)`;
    }

    const co2LimitEl = gaugeEl?.querySelector('#co2-over-limit');
    if (value > MAX_VALUE) {
      co2LimitEl.x2.baseVal.value = (value - MAX_VALUE) / (END / gaugeEl.clientWidth);
    } else {
      co2LimitEl.x2.baseVal.value = 0;
    }
  }

  return (
    <div className={`co2-container ${showWarning ? 'warning' : ''}`}>
      <div className="co2-label-container">
        <CO2Icon />
        <div className="co2-label">
          C0
          <sub>2</sub>
        </div>
        <InfoTipIcon className="info-tip" />
      </div>
      <div className="co2-value-container">
        <div className="co2-value">{value || 0}</div>
      </div>
      <div className="co2-gauge-container">
        <CO2Scale className="co2-gauge-scale" ref={gaugeRef} />
        <Needle className="co2-gauge-needle" ref={needleRef} />
      </div>
      <div className="set-limits-container">
        <span className="set-limits-label">Set limits:</span>
        <span className="set-limits-value">{MIN_VALUE}</span>
        <span className="set-limits-label"> - </span>
        <span className="set-limits-value">{`${MAX_VALUE} ppm`}</span>
      </div>
    </div>
  );
};

CO2.propTypes = {
  value: PropTypes.number.isRequired,
  deviceCo2: PropTypes.instanceOf(Object),
};

CO2.defaultProps = {
  deviceCo2: { min: 0, max: 1000 },
};

export default CO2;

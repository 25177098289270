import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Slider from '@mui/material/Slider';
import { defaultLimits } from '../../../utils';
import { setNewCurrentDevice } from '../../DevicesTable/modals/SettingsModal/actions';
import './styles.scss';

const ControlsSlider = ({
  name, objOfMeasuring,
  setNewCurrentDevice: setNewCurrentDeviceFromProps,
}) => {
  /**
 * @name minDistance
 * @type <number>
 * @description We can enforce a minimum distance between values in the onChange event handler;
 */
  const minDistance = 5;

  const handleChange = (e, newValue, activeThumb) => {
    const localCurrentDevice = {};
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      localCurrentDevice[name] = {
        min: Math.min(newValue[0], objOfMeasuring.max - minDistance),
        max: objOfMeasuring.max,
      };
      setNewCurrentDeviceFromProps(localCurrentDevice);
    } else {
      localCurrentDevice[name] = {
        min: objOfMeasuring.min,
        max: Math.max(newValue[1], objOfMeasuring.min + minDistance),
      };
      setNewCurrentDeviceFromProps(localCurrentDevice);
    }
  };

  return (
    <div
      className="slider"
    >
      <h1 className="slider__name">{name}</h1>
      <Slider
        getAriaLabel={() => 'Minimum distance'}
        min={defaultLimits[name].min}
        max={defaultLimits[name].max}
        value={[objOfMeasuring.min, objOfMeasuring.max]}
        onChange={handleChange}
        valueLabelDisplay="auto"
        disableSwap={false}
      />
      <p className="slider__limit">
        Min :
        {objOfMeasuring.min}
      </p>
      <p className="slider__limit">
        Max :
        {objOfMeasuring.max}
      </p>
    </div>
  );
};

ControlsSlider.propTypes = {
  objOfMeasuring: PropTypes.instanceOf(Object).isRequired,
  setNewCurrentDevice: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({
    setNewCurrentDevice,
  }, dispatch),
});

export default connect(null, mapDispatchToProps)(ControlsSlider);

import React from 'react';

import { ReactComponent as ExclamationIcon } from '../../assets/images/ExclamationIcon.svg';

import './styles.scss';

const NoInternetConnection = () => (
  <div className="no-internet-connection">
    <div className="no-internet-connection__warning-container">
      <ExclamationIcon />
      <div className="no-internet-connection__header">
        No internet connection!
      </div>
      <div className="no-internet-connection__description">
        Connect to Wi-Fi or use mobile data and try again.
      </div>
    </div>
  </div>
);

export default NoInternetConnection;

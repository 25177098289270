import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { deleteDevice, setOpen } from '../../DevicesTable/actions';
import { setNewCurrentDevice } from '../../DevicesTable/modals/SettingsModal/actions';
import { DELETE_DEVICE_BY_NAME, SHOW_EDIT, SHOW_SETTING } from '../../DevicesTable/constants';
import {
  ADD_MODAL,
  BLE_MODAL,
  EDIT_MODAL,
  QR_MODAL,
  SETTING_MODAL,
} from '../../../utils/generalConstants';
import { devicesSelector } from './selectors';

const ControlsButton = ({
  onClick, className, children, deviceName, action, devices,
  setOpen: setOpenFromProps,
  deleteDevice: deleteDeviceFromProps,
  setNewCurrentDevice: setNewCurrentDeviceFromProps,
}) => {
  const onClickHandler = useCallback((e) => {
    const currentDevice = devices.find((device) => device.name === deviceName);
    e.preventDefault();
    switch (action) {
      case SHOW_SETTING:
        setNewCurrentDeviceFromProps(currentDevice);
        setOpenFromProps(SETTING_MODAL);
        break;
      case SHOW_EDIT:
        setNewCurrentDeviceFromProps(currentDevice);
        setOpenFromProps(EDIT_MODAL);
        break;
      case DELETE_DEVICE_BY_NAME:
        deleteDeviceFromProps(deviceName);
        break;
      case BLE_MODAL:
        setOpenFromProps(BLE_MODAL);
        break;
      case ADD_MODAL:
        setOpenFromProps(ADD_MODAL);
        break;
      case QR_MODAL:
        setOpenFromProps(QR_MODAL);
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceName, devices]);

  return (
    <button
      type="button"
      className={className}
      onClick={onClickHandler || onClick}
    >
      {children}
    </button>
  );
};

ControlsButton.propTypes = {
  devices: PropTypes.instanceOf(Object).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  setNewCurrentDevice: PropTypes.func.isRequired,
  deleteDevice: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  setOpen: PropTypes.func.isRequired,
  deviceName: PropTypes.string,
  action: PropTypes.string,
  onClick: PropTypes.func,
};

ControlsButton.defaultProps = {
  onClick: () => { },
  deviceName: '',
  action: '',
};

const mapStateToProps = createStructuredSelector({
  devices: devicesSelector,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({
    setOpen,
    deleteDevice,
    setNewCurrentDevice,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ControlsButton);

import React, { useState, useEffect } from 'react';
import * as workerTimers from 'worker-timers';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { DEV_BASE_URI } from '../../api/endpoints';
import Temperature from './Temperature';
import Humidity from './Humidity';
import Pressure from './Pressure';
import CO2 from './CO2';
import TVOC from './TVOC';
import { fetchDeviceData, fetchTelemetry, initialState } from '../../utils';
import { devicesSelector } from './selectors';
import './styles.scss';

const TelemetryPage = ({ devices }) => {
  const params = useParams();
  const currentDevice = devices.find((device) => device.id === params.id) || {};
  const [telemetry, setTelemetry] = useState(initialState);
  const [requestInterval, updateRequestInterval] = useState(null);

  const {
    id: deviceId,
    temperature: deviceTemperature,
    humidity: deviceHumidity,
    pressure: devicePressure,
    co2: deviceCo2,
    tvoc: deviceTvoc,
  } = currentDevice;

  useEffect(() => {
    if (deviceId) {
      if (requestInterval) {
        workerTimers.clearInterval(requestInterval);
        updateRequestInterval(null);
      }
      updateRequestInterval(() => workerTimers.setInterval(async () => {
        const deviceDataWithInterval = await fetchDeviceData(`${DEV_BASE_URI}/stream/${deviceId}`);
        const emptyDates = {
          temperature: 0, humidity: 0, pressure: 0, co2: 0, tvoc: 0,
        };
        setTelemetry(deviceDataWithInterval || emptyDates);
      }, 1000));
    } else {
      fetchTelemetry(setTelemetry);
      if (requestInterval) {
        workerTimers.clearInterval(requestInterval);
        updateRequestInterval(null);
      }
      updateRequestInterval(() => workerTimers.setInterval(fetchTelemetry.bind(null, setTelemetry), 1000));
    }
    return () => {
      workerTimers.clearTimeout(requestInterval);
      updateRequestInterval(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceId]);

  const {
    temperature,
    humidity,
    pressure,
    co2,
    tvoc,
  } = telemetry;

  return (
    <div className="telemetry-container">
      <Temperature value={temperature || 0} deviceTemperature={deviceTemperature} />
      <Humidity value={humidity || 0} deviceHumidity={deviceHumidity} />
      <Pressure value={pressure || 0} devicePressure={devicePressure} />
      <CO2 value={co2 || 0} deviceCo2={deviceCo2} />
      <TVOC value={tvoc || 0} deviceTvoc={deviceTvoc} />
    </div>
  );
};

TelemetryPage.propTypes = {
  devices: PropTypes.arrayOf(PropTypes.object).isRequired,
};
const mapStateToProps = createStructuredSelector({
  devices: devicesSelector,
});

export default connect(mapStateToProps)(TelemetryPage);

import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Input } from '../formControls';
import { required } from '../validate';
import '../../DevicesTable/modals/BluetoothModal/styles.scss';
import { BLE_FORM } from '../../../utils/generalConstants';
import './styles.scss';
import Button from '../../UI/button';

const BluetoothModalForm = ({
  handleSubmit, submitting, handleShowQr, handleCloseBluetooth, invalid, pristine,
}) => (
  <form onSubmit={handleSubmit} className="bluetooth-modal-form">
    <h1 className="bluetooth-modal-form__description">Select your Wi-Fi and enter its password</h1>
    <Field
      className="bluetooth-modal-form__input"
      name="name"
      type="text"
      placeholder="Write your network name*"
      component={Input}
      label="Select a Wi-Fi network (SSID)"
      validate={[required]}
    />
    <Field
      className="bluetooth-modal-form__input"
      name="password"
      type="text"
      placeholder="Wi-Fi password*"
      component={Input}
      label="Wi-Fi password"
      validate={[required]}
    />
    <h1 className="bluetooth-modal-form__description">Specify at which interval UBox should take measurements</h1>
    <Field
      className="bluetooth-modal-form__input"
      name="measuring"
      type="text"
      placeholder="Write your measuring"
      component={Input}
      label="Measuring interval (2-60 minutes)"
      validate={[required]}
    />

    <div className="bluetooth-modal-form__buttons">
      <Button text="SUBMIT" type="submit" disabled={invalid || submitting || pristine} />
      <Button text="ADD WITH QR" onClick={handleShowQr} disabled={submitting} />
      <Button text="CANCEL" onClick={handleCloseBluetooth} disabled={submitting} />
    </div>

  </form>

);

BluetoothModalForm.propTypes = {
  handleCloseBluetooth: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleShowQr: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
};

const form = reduxForm({
  form: BLE_FORM,
})(BluetoothModalForm);

export default form;

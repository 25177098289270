import React, { memo } from 'react';
import PropTypes from 'prop-types';
import ControlsButton from '../../UI/controlsButton';
import { DELETE_DEVICE_BY_NAME, SHOW_EDIT, SHOW_SETTING } from '../constants';
import './styles.scss';

const ContainerOfButtons = memo(({ name }) => (
  <li className="device">
    <span>{name}</span>
    <div>
      <ControlsButton
        className="device-btn__settings"
        deviceName={name}
        action={SHOW_SETTING}
      >
        <i className="bi bi-sliders" />
      </ControlsButton>
      <ControlsButton
        className="device-btn__edit"
        deviceName={name}
        action={SHOW_EDIT}
      >
        <i className="bi bi-pencil-square" />
      </ControlsButton>
      <ControlsButton
        className="device-btn__delete"
        deviceName={name}
        action={DELETE_DEVICE_BY_NAME}
      >
        <i className="bi bi-trash" />
      </ControlsButton>
    </div>
  </li>
));

ContainerOfButtons.propTypes = {
  name: PropTypes.string.isRequired,
};

export default ContainerOfButtons;

import React from 'react';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { resetNewCurrentDevice } from './actions';
import { setClose } from '../../actions';
import SettingModalForm from '../../../Forms/SettingModalForm';
import { SETTING_MODAL } from '../../../../utils/generalConstants';
import './styles.scss';
import { isOpenSelector, typeOfModalSelector } from './selectors';

const SettingsModal = ({
  isOpen, typeOfModal,
  setClose: setCloseFromProps,
  resetNewCurrentDevice: resetNewCurrentDeviceFromProps,
}) => {
  const handleCloseSettings = () => {
    setCloseFromProps();
    resetNewCurrentDeviceFromProps();
  };
  return (
    <Modal
      size="lg"
      show={isOpen && typeOfModal === SETTING_MODAL}
      onHide={handleCloseSettings}
    >
      <div className="settings-modal">
        <div>
          <h1 className="settings-modal__title">Edit your values</h1>
        </div>
        <SettingModalForm handleCloseSettings={handleCloseSettings} />
      </div>
    </Modal>
  );
};

SettingsModal.propTypes = {
  resetNewCurrentDevice: PropTypes.func.isRequired,
  typeOfModal: PropTypes.string.isRequired,
  setClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};
const mapStateToProps = createStructuredSelector({
  isOpen: isOpenSelector,
  typeOfModal: typeOfModalSelector,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({
    setClose,
    resetNewCurrentDevice,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsModal);

export const SET_NEW_DEVICE = 'SET_NEW_DEVICE';
export const DELETE_DEVICE = 'DELETE_DEVICE';
export const SET_EDIT_DATA = 'SET_EDIT_DATA';

export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

export const SET_ID_ERROR = 'SET_ID_ERROR';
export const SET_NAME_ERROR = 'SET_NAME_ERROR';
export const SET_ID = 'SET_ID';
export const SET_NAME = 'SET_NAME';

export const SET_VALUES = 'SET_VALUES';
export const SET_ERRORS = 'SET_ERRORS';
export const SET_NEW_DATA = 'SET_NEW_DATA';
export const SET_CHANGED_VALUES = 'SET_CHANGED_VALUES';
export const SET_CHANGED_SETTING_VALUES = 'SET_CHANGED_SETTING_VALUES';

export const SHOW_SETTING = 'show_setting';
export const SHOW_EDIT = 'show_edit';
export const DELETE_DEVICE_BY_NAME = 'delete_device';

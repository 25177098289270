import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { setChangedSettingValues } from '../../DevicesTable/actions';
import Button from '../../UI/button';
import ControlsSlider from '../../UI/slider';
import { currentDeviceSelector } from './selectors';
import './styles.scss';

const SettingModalForm = ({
  handleCloseSettings, currentDevice,
  setChangedSettingValues: setChangedSettingValuesFromProps,
}) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    setChangedSettingValuesFromProps(currentDevice);
    handleCloseSettings();
  };
  /**
 * @name namesOfMeasuring
 * @type <array>
 * @description Array of names of measuring
 */
  const namesOfMeasuring = ['temperature', 'humidity', 'pressure', 'co2', 'tvoc'];
  return (
    <form onSubmit={handleSubmit} className="settings-modal-form">
      <div className="settings-modal-form__sliders">
        {namesOfMeasuring.map((name) => (
          <ControlsSlider key={name} name={name} objOfMeasuring={currentDevice[name]} />
        ))}
      </div>
      <div className="settings-modal-form__buttons">
        <Button type="submit" text="SUBMIT" />
        <Button onClick={handleCloseSettings} text="CANCEL" />
      </div>
    </form>
  );
};

SettingModalForm.propTypes = {
  currentDevice: PropTypes.instanceOf(Object).isRequired,
  setChangedSettingValues: PropTypes.func.isRequired,
  handleCloseSettings: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  currentDevice: currentDeviceSelector,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({
    setChangedSettingValues,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingModalForm);

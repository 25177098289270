import React, { memo } from 'react';
import { ADD_MODAL, BLE_MODAL, QR_MODAL } from '../../../utils/generalConstants';
import ControlsButton from '../../UI/controlsButton';
import './styles.scss';

const ContainerOfButtons = memo(() => (
  <div className="list-method">
    <ControlsButton
      className="list-method__add"
      action={BLE_MODAL}
    >
      <div className="icon-plus">
        <i className="bi bi-bluetooth" />
      </div>
    </ControlsButton>
    <ControlsButton
      className="list-method__add"
      action={ADD_MODAL}
    >
      <div className="icon-plus">
        <i className="bi bi-plus-lg" />
      </div>
    </ControlsButton>
    <ControlsButton
      className="list-method__scan-qr"
      action={QR_MODAL}
    >
      <div className="icon-qr-code">
        <i className="bi bi-qr-code" />
      </div>
    </ControlsButton>
  </div>

));

export default ContainerOfButtons;

/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

export const Input = memo(({
  input, label, meta, ...props
}) => {
  const hasError = meta.touched && meta.error;
  return (
    <div>
      <label>{label}</label>
      <div>
        <input {...input} {...props} />
      </div>
      {hasError && <span className="input-error">{meta.error}</span>}
    </div>
  );
});

Input.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.instanceOf(Object).isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import AddDeviceModal from './modals/AddDeviceModal';
import SettingsModal from './modals/SettingsModal';
import BluetoothModal from './modals/BluetoothModal';
import ContainerOfBottomButtons from './containerOfButtons/ContainerOfBottomButtons';
import ContainerOfDevicesButtons from './containerOfButtons/ContainerOfDevicesButtons';
import {
  ADD_MODAL, BLE_MODAL, EDIT_MODAL, QR_MODAL, SETTING_MODAL,
} from '../../utils/generalConstants';
import './styles.scss';
import { devicesSelector, typeOfModalSelector } from './selectors';

const DevicesTable = ({ typeOfModal, devices }) => (
  <div className="devices-table">
    <div className="devices-table__title">
      <h2>Devices</h2>
      {devices && <div className="devices-table__devices-length">{devices.length}</div>}
    </div>
    {devices.length ? (
      <ul
        className="devices-table__devices-list"
      >
        {devices.map(({ id, name }) => (
          <Link
            key={id}
            className="devices-table__device-link"
            to={`/telemetry/${id}`}
          >
            <ContainerOfDevicesButtons name={name} />
          </Link>
        ))}
      </ul>
    ) : (
      <h4 className="devices-table__empty-list">
        You have no added devices!
      </h4>
    )}

    <ContainerOfBottomButtons />

    {[ADD_MODAL, EDIT_MODAL, QR_MODAL].includes(typeOfModal) && (<AddDeviceModal />)}
    {typeOfModal === SETTING_MODAL && (<SettingsModal />)}
    {typeOfModal === BLE_MODAL && (<BluetoothModal />)}

  </div>
);

DevicesTable.propTypes = {
  devices: PropTypes.instanceOf(Object).isRequired,
  typeOfModal: PropTypes.string.isRequired,
};
const mapStateToProps = createStructuredSelector({
  typeOfModal: typeOfModalSelector,
  devices: devicesSelector,
});

export default connect(mapStateToProps)(DevicesTable);

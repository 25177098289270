import React from 'react';

import HomePage from './components/HomePage';
import DevicesTable from './components/DevicesTable';
import TelemetryPage from './components/TelemetryPage';
import NotFound from './components/NotFound';

const routes = [
  {
    key: '#HomePage',
    path: '/home',
    exact: true,
    element: <HomePage />,
  },
  {
    key: '#TelemetryPageTest',
    path: '/',
    exact: true,
    element: <TelemetryPage />,
  },
  {
    key: '#DevicesTable',
    path: '/devices',
    exact: true,
    element: <DevicesTable />,
  },
  {
    key: '#TelemetryPage',
    path: '/telemetry/:id',
    exact: true,
    element: <TelemetryPage />,
  },
  {
    key: '#Redirect',
    path: '*',
    element: <NotFound />,
  },
];

export default routes;

import * as actionTypes from './constants';

export const currentDeviceReducer = (
  state = {},
  { type, payload },
) => {
  switch (type) {
    case actionTypes.SET_NEW_CURRENT_DEVICE: {
      const { currentDevice } = payload;
      return {
        ...state,
        ...currentDevice,
      };
    }
    case actionTypes.RESET_NEW_CURRENT_DEVICE: {
      return {};
    }
    default:
      return state;
  }
};

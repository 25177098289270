import React from 'react';
import { Link } from 'react-router-dom';
import './styles.scss';

const NotFound = () => (
  <div className="not-found">
    <h1 className="not-found__description">404 page not found</h1>
    <Link className="not-found__notify-btn" to="/home">
      Go to home
    </Link>
  </div>
);

export default NotFound;

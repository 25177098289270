import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import QrReader from 'react-qr-reader';
import { change } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { connectToDeviceAndSubscribeToUpdates } from '../../../../utils';
import { addNotification } from '../../../Notification/actions';
import { setClose } from '../../actions';
import BluetoothModalForm from '../../../Forms/BluetoothModalForm';
import { BLE_FORM, BLE_MODAL } from '../../../../utils/generalConstants';
import './styles.scss';
import {
  currentDeviceSelector, devicesSelector, isOpenSelector, typeOfModalSelector,
} from './selectors';

const BluetoothModal = ({
  isOpen, typeOfModal,
  setClose: setCloseFromProps,
  addNotification: addNotificationFromProps,
  change: changeFromProps,
}) => {
  const [showQr, setShowQr] = useState(false);
  const handleShowQr = () => setShowQr(!showQr);

  const handleScan = (data) => {
    if (data) {
      const [, WifiPassword = '', WifiName = ''] = data.split(';');
      changeFromProps(BLE_FORM, 'name', WifiName.split(':')[1]);
      changeFromProps(BLE_FORM, 'password', WifiPassword.split(':')[1]);
      setShowQr(false);
    }
  };
  const handleError = (err) => console.error(err);

  const handleCloseBluetooth = () => {
    setCloseFromProps();
    setShowQr(false);
  };

  const handleSubmit = async (formValues) => {
    addNotificationFromProps('Choose your device...');
    if (navigator.bluetooth) {
      await connectToDeviceAndSubscribeToUpdates({ formValues, addNotificationFromProps });
      addNotificationFromProps('');
      handleCloseBluetooth();
    } else {
      addNotificationFromProps('Turn on your BLE...');
    }
  };

  return (
    <Modal
      size="lg"
      show={isOpen && typeOfModal === BLE_MODAL}
      onHide={handleCloseBluetooth}
    >
      <div className="bluetooth-modal">
        <div>
          <h1>Connecting UBox to your Wi-Fi network</h1>
        </div>
        {showQr && (
          <div className="bluetooth-modal__QR">
            <QrReader
              className="bluetooth-modal__QR-reader"
              delay={400}
              onError={handleError}
              onScan={handleScan}
            />
          </div>
        )}
        <BluetoothModalForm
          onSubmit={handleSubmit}
          handleShowQr={handleShowQr}
          handleCloseBluetooth={handleCloseBluetooth}
        />
      </div>
    </Modal>
  );
};
BluetoothModal.propTypes = {
  typeOfModal: PropTypes.string.isRequired,
  setClose: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  addNotification: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = createStructuredSelector({
  isOpen: isOpenSelector,
  typeOfModal: typeOfModalSelector,
  currentDevice: currentDeviceSelector,
  devices: devicesSelector,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({
    setClose,
    addNotification,
    change,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(BluetoothModal);

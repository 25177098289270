import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as PressureGauge } from '../../../assets/images/PressureGauge.svg';
import { ReactComponent as PressureIcon } from '../../../assets/images/PressureIcon.svg';
import { ReactComponent as InfoTipIcon } from '../../../assets/images/InfoTipIcon.svg';
import { ReactComponent as PressureNeedle } from '../../../assets/images/PressureNeedle.svg';

import './styles.scss';

const Pressure = (props) => {
  const { value, devicePressure: { min: MIN_VALUE, max: MAX_VALUE } } = props;
  const PASCAL_TO_MMHG = 133.322;

  const valueInMmhg = Math.round(value / PASCAL_TO_MMHG) || 0;

  const showWarning = !!valueInMmhg && (valueInMmhg < MIN_VALUE || valueInMmhg > MAX_VALUE);

  const needleRef = useRef(null);
  if (needleRef?.current) {
    needleRef.current.style = `transform: rotate(${(valueInMmhg / 5.555555556) - 134}deg)`;
  }

  return (
    <div className={`pressure-container  ${showWarning ? 'warning' : ''}`}>
      <div className="pressure-description">
        <div className="pressure-label-container">
          <PressureIcon />
          <div className="pressure-label">PRESSURE</div>
          <InfoTipIcon className="info-tip" />
        </div>
        <div className="set-limits-container">
          <span className="set-limits-label">Set limits:</span>
          <span className="set-limits-value">{MIN_VALUE}</span>
          <span className="set-limits-label"> - </span>
          <span className="set-limits-value">{`${MAX_VALUE}mmHg`}</span>
        </div>
      </div>
      <div className="pressure-gauge-container">
        <PressureGauge className="tempereature-gauge-scale" />
        <PressureNeedle className="tempereature-gauge-needle" ref={needleRef} />
        <div className="pressure-value-container">
          <div className="pressure-value">{valueInMmhg}</div>
        </div>
      </div>
      <div className="set-limits-container set-limits-container-sm">
        <span className="set-limits-label">Set limits:</span>
        <span className="set-limits-value">{MIN_VALUE}</span>
        <span className="set-limits-label"> - </span>
        <span className="set-limits-value">{`${MAX_VALUE}mmHg`}</span>
      </div>
    </div>
  );
};

Pressure.propTypes = {
  value: PropTypes.number.isRequired,
  devicePressure: PropTypes.instanceOf(Object),
};

Pressure.defaultProps = {
  devicePressure: { min: 730, max: 760 },
};

export default Pressure;

import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as TVOCScale } from '../../../assets/images/TVOCScale.svg';
import { ReactComponent as TVOCIcon } from '../../../assets/images/TVOCIcon.svg';
import { ReactComponent as InfoTipIcon } from '../../../assets/images/InfoTipIcon.svg';
import { ReactComponent as VerticalNeedle } from '../../../assets/images/VerticalNeedle.svg';

import './styles.scss';

const TVOC = (props) => {
  const { value, deviceTvoc: { min: MIN_VALUE, max: MAX_VALUE } } = props;
  const START = 0;
  const END = 2000;

  const showWarning = !!value && value > MAX_VALUE;

  const gaugeRef = useRef(null);
  const needleRef = useRef(null);

  if (gaugeRef?.current && needleRef?.current) {
    const gaugeEl = gaugeRef?.current;
    const needleEl = needleRef.current;

    if (value < START + needleEl.clientWidth / 2) {
      needleEl.style = `left: -${needleEl.clientWidth / 2}px`;
    } else if (value > END) {
      needleEl.style = `left: calc(${gaugeEl.clientWidth}px - ${needleEl.clientWidth / 2}px)`;
    } else {
      needleEl.style = `left: calc(${value / (END / gaugeEl.clientWidth)}px - ${needleEl.clientWidth / 2}px)`;
    }

    const tvocLimitEl = gaugeEl?.querySelector('#tvoc-over-limit');
    if (value > MAX_VALUE) {
      tvocLimitEl.x2.baseVal.value = (value - MAX_VALUE) / (END / gaugeEl.clientWidth) - 3.5;
    } else {
      tvocLimitEl.x2.baseVal.value = 0;
    }
  }

  return (
    <div className={`tvoc-container ${showWarning ? 'warning' : ''}`}>
      <div className="tvoc-label-container">
        <TVOCIcon />
        <div className="tvoc-label">TVOC</div>
        <InfoTipIcon className="info-tip" />
      </div>
      <div className="tvoc-value-container">
        <div className="tvoc-value">{value || 0}</div>
      </div>
      <div className="tvoc-gauge-container">
        <TVOCScale className="tvoc-gauge-scale" ref={gaugeRef} />
        <VerticalNeedle className="tvoc-gauge-needle" ref={needleRef} />
      </div>
      <div className="set-limits-container">
        <span className="set-limits-label">Set limits:</span>
        <span className="set-limits-value">{MIN_VALUE}</span>
        <span className="set-limits-label"> - </span>
        <span className="set-limits-value">{`${MAX_VALUE} µg/m³`}</span>
      </div>
    </div>
  );
};

TVOC.propTypes = {
  value: PropTypes.number.isRequired,
  deviceTvoc: PropTypes.instanceOf(Object),
};

TVOC.defaultProps = {
  deviceTvoc: { min: 0, max: 500 },
};

export default TVOC;

import React from 'react';
import { Link } from 'react-router-dom';
import './styles.scss';

const HomePage = () => (
  <div className="home-page">
    <h1 className="home-page__description">Welcome to awesome project Ubreez!</h1>
    <Link className="home-page__notify-btn" to="/devices">
      Get start
    </Link>
  </div>
);

export default HomePage;

import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as TemperatureGauge } from '../../../assets/images/TemperatureGauge.svg';
import { ReactComponent as TemperatureIcon } from '../../../assets/images/TemperatureIcon.svg';
import { ReactComponent as InfoTipIcon } from '../../../assets/images/InfoTipIcon.svg';
import { ReactComponent as TemperatureNeedle } from '../../../assets/images/TemperatureNeedle.svg';

import './styles.scss';

const Temperature = (props) => {
  const { value, deviceTemperature: { min: MIN_VALUE, max: MAX_VALUE } } = props;

  /**
   * Returns sign depending on value
   * @param {number|null} val - temperature
   * @returns {string} - "+", "-" or ""
   */
  const getSign = (val) => {
    if (val === 0) {
      return '';
    }
    return val > 0 ? '+' : '-';
  };
  const showWarning = !!value && (value < MIN_VALUE || value > MAX_VALUE);

  const temperatureNeedleRef = useRef(null);
  if (temperatureNeedleRef?.current) {
    temperatureNeedleRef.current.style = `transform: rotate(${((value || 0) + 50) * 3.25 - 241}deg)`;
  }

  return (
    <div className={`temperature-container ${showWarning ? 'warning' : ''}`}>
      <div className="temperature-label-container">
        <TemperatureIcon />
        <div className="temperature-label">TEMPERATURE</div>
        <InfoTipIcon className="info-tip" />
      </div>
      <div className="temperature-gauge-container">
        <div className="tempereature-gauge-scale">
          <TemperatureGauge />
          <div className="minus-plus">-&nbsp;&nbsp;&nbsp;+</div>
        </div>
        <div className="temperature-gauge-needle">
          <TemperatureNeedle className="temperature-needle" ref={temperatureNeedleRef} />
        </div>
        <div className="temperature-value-container">
          <div className="temperature-sign">{getSign(value)}</div>
          <div className="temperature-value">{Math.abs(value) || 0}</div>
          <div className="temperature-unit">°C</div>
        </div>
      </div>
      <div className="set-limits-container">
        <span className="set-limits-label">Set limits:</span>
        <span className="set-limits-value">{`${getSign(MIN_VALUE)}${MIN_VALUE}`}</span>
        <span className="set-limits-label">-</span>
        <span className="set-limits-value">{`${getSign(MAX_VALUE)}${MAX_VALUE} °C`}</span>
      </div>
    </div>
  );
};

Temperature.propTypes = {
  value: PropTypes.number.isRequired,
  deviceTemperature: PropTypes.instanceOf(Object),
};

Temperature.defaultProps = {
  deviceTemperature: { min: 20, max: 25 },
};

export default Temperature;

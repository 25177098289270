import * as actionTypes from './constants';
import {
  filterDevices, getNewLocalStorage, initialDevice, setNewDevices, typeOfSending,
} from '../../utils';

export const storageDevicesReducer = (
  state = setNewDevices(),
  { type, payload },
) => {
  switch (type) {
    case actionTypes.SET_NEW_DEVICE: {
      const { id, name } = payload.deviceData;
      const newLocalStorage = [...state, { ...initialDevice, id, name }];
      setNewDevices(newLocalStorage, typeOfSending);
      return newLocalStorage;
    }
    case actionTypes.SET_EDIT_DATA: {
      const { editData: { id = '', name = '' }, currentDevice = {} } = payload;
      const newLocalStorage = getNewLocalStorage(currentDevice, state, { id, name });
      setNewDevices(newLocalStorage, typeOfSending);
      return newLocalStorage;
    }
    case actionTypes.SET_CHANGED_SETTING_VALUES: {
      const newLocalStorage = getNewLocalStorage(payload.currentDevice, state);
      setNewDevices(newLocalStorage, typeOfSending);
      return newLocalStorage;
    }
    case actionTypes.DELETE_DEVICE: {
      const filteredLocalStorage = filterDevices(payload.deviceName, state);
      setNewDevices(filteredLocalStorage, typeOfSending);
      return filteredLocalStorage;
    }
    default:
      return state;
  }
};

export const modalReducer = (
  state = {
    isOpen: false,
    typeOfModal: '',
  },
  { type, payload },
) => {
  switch (type) {
    case actionTypes.OPEN_MODAL:
      return {
        ...state,
        isOpen: true,
        typeOfModal: payload.typeOfModal,
      };
    case actionTypes.CLOSE_MODAL:
      return {
        ...state,
        isOpen: false,
        typeOfModal: '',
      };
    default:
      return state;
  }
};

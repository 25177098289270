import React from 'react';

import { ReactComponent as Logo } from '../../assets/images/Logo.svg';

import './styles.scss';

const TopBar = () => (
  <div className="top-bar">
    <Logo />
  </div>
);

export default TopBar;

import React from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { createStructuredSelector } from 'reselect';
import Snackbar from '@mui/material/Snackbar';
import { connect } from 'react-redux';
import { addNotification } from './actions';
import { notificationTextSelector } from './selectors';

const Notification = ({ notification, addNotification: addNotificationFromProps }) => {
  const handleClose = (reason) => {
    if (reason !== 'clickaway') {
      addNotificationFromProps('');
    }
  };
  const action = (
    <>
      <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button>
    </>
  );

  return (
    <div>
      <Snackbar
        key={new Date().getTime()}
        open={!!notification}
        autoHideDuration={2000}
        onClose={handleClose}
        message={notification}
        action={action}
      />
    </div>
  );
};

Notification.propTypes = {
  addNotification: PropTypes.func.isRequired,
  notification: PropTypes.string.isRequired,
};

const mapStateToProps = createStructuredSelector({
  notification: notificationTextSelector,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({
    addNotification,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
